import * as Yup from "yup";
import { JourneyCompanyType } from "../interfaces/CompanyType";
import { FormInput } from "../interfaces/Form";
import { LegalOptionsSliceItem, ListOptions } from "../lib/formatAppData";
import { testValidPhone } from "../lib/testValidPhone";
import { Regexes } from "../models/regexes.model";
import { SupplierReference } from "../models/supplier-config.model";

// TODO: Remove all validation from this schema and only add it if the component exists
const shape = {
  contactInformation: Yup.object(),
  directDebit: Yup.object(),
  legals: Yup.object().shape({
    supplier_0: Yup.boolean().oneOf([true], "Must accept the terms and conditions"),
    supplier_1: Yup.boolean().oneOf([true], "Must accept the terms and conditions"),
  }),
  mandatory: Yup.object(),
  optional: Yup.object(),
  payments: Yup.object(),
};

export interface ContactDetailsInterface
  extends Yup.Asserts<ReturnType<typeof makeContactDetailsSchema>> {}

export const makeContactDetailsSchema = (
  listOptions: ListOptions,
  suppliers: Set<SupplierReference>,
  components: FormInput[],
  companyType: JourneyCompanyType | undefined
) => {
  const mandatory = buildMandatoryLegalYup(listOptions.mandatory_legal_options);
  const optional = buildOptionalLegalYup(listOptions.optional_legal_options);
  shape.mandatory = mandatory;
  shape.optional = optional;

  if (
    !onlyValdaSupplier(suppliers) &&
    components.some((component) => component.type === "payment-details")
  ) {
    shape.directDebit = buildDirectDebitYup();
  }

  if (components.some((component) => component.type === "gocardless-payment")) {
    shape.payments = buildPaymentsYup();
  }

  shape.contactInformation = buildContactInformationYup(companyType);

  return Yup.object().shape(shape);

  function onlyValdaSupplier(suppliers: Set<SupplierReference>): boolean {
    return suppliers.size === 1 && suppliers.has("valda");
  }
};

const buildContactInformationYup = (companyType: JourneyCompanyType | undefined) => {
  const contactInformationShape: Record<string, any> = {
    firstname: Yup.string()
      .required("Required")
      .test("account-name", "Please enter a valid name", (value: string) => {
        return /^([^0-9]*)$/.test(value);
      }),
    surname: Yup.string()
      .required("Required")
      .test("account-name", "Please enter a valid name", (value: string) => {
        return /^([^0-9]*)$/.test(value);
      }),
    email: Yup.string().required("Required").email("Please enter a valid email"),
    phoneNumber: Yup.string()
      .required("Required")
      .test("phone-number", "Please enter a valid UK Phone number", testValidPhone),
  };

  if (companyType === JourneyCompanyType.SoleTrader) {
    contactInformationShape.personalAddress = Yup.object().shape({
      addressLine1: Yup.string().required("Required").nullable(),
      addressLine2: Yup.string().optional().nullable(),
      addressLine3: Yup.string().optional().nullable(),
      town: Yup.string().optional().nullable(),
      county: Yup.string().optional().nullable(),
      postCode: Yup.string()
        .required("Required")
        .test(
          "post-code",
          "Please enter a valid post code",
          (value: string) =>
            value !== undefined && value.length > 0 && Regexes.POSTCODE.test(value)
        ),
    });
    contactInformationShape.yearsActive = Yup.number().required("Required");
    contactInformationShape.businessActivity = Yup.string().required("Required");
    contactInformationShape.dateOfBirth = Yup.string()
      .trim()
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
        'Please provide correct date format'
      )
      .required('Required');
  }

  return Yup.object().shape(contactInformationShape);
};

const buildMandatoryLegalYup = (data: Array<LegalOptionsSliceItem>) => {
  const shape: Record<string, any> = {};
  for (const item of data) {
    shape[item.key] = Yup.boolean().required().oneOf([true], "Must accept the terms");
  }
  return Yup.object().shape(shape);
};

const buildOptionalLegalYup = (data: Array<LegalOptionsSliceItem>) => {
  const shape: Record<string, any> = {};
  for (const item of data) {
    shape[item.key] = Yup.boolean().optional();
  }
  return Yup.object().shape(shape);
};

const buildDirectDebitYup = () => {
  return Yup.object().shape({
    accountHolderName: Yup.string()
      .required("Required")
      .test("account-name", "Please enter a valid name", (value: string) => {
        return /^([^0-9]*)$/.test(value);
      }),
    accountNumber: Yup.string()
      .required("Required")
      .test(
        "account-num",
        "Account number number be 7 or 8 Digits long",
        (value: string) => {
          return /^(\d){7,8}$/.test(value);
        }
      ),
    sortCode: Yup.string()
      .required("Required")
      .test("sort-code", "Please enter a valid sort code", (value: string) => {
        return (
          /(?!0{2}(-?0{2}){2})(\d{2}(\d{2}){2})|(\d{6})/.test(value) &&
          typeof value !== "undefined" &&
          value.length > 0
        );
      }),
    authorisation: Yup.boolean()
      .required("Required")
      .oneOf(
        [true],
        "You must be the account holder or have the account holders permission to continue"
      ),
    sameBillingAddress: Yup.boolean(),
    billingAddress: Yup.object().when("sameBillingAddress", {
      is: (value: string) => !value,
      then: Yup.object().shape({
        addressLine1: Yup.string().required("Required"),
        addressLine2: Yup.string().optional(),
        addressLine3: Yup.string().optional(),
        town: Yup.string().required("Required"),
        county: Yup.string().optional(),
        postCode: Yup.string()
          .required("Required")
          .test(
            "post-code",
            "Please enter a valid post code",
            (value: string) =>
              value !== undefined && value.length > 0 && Regexes.POSTCODE.test(value)
          ),
      }),
      otherwise: Yup.object().shape({
        addressLine1: Yup.string().optional(),
        addressLine2: Yup.string().optional(),
        addressLine3: Yup.string().optional(),
        town: Yup.string().optional(),
        county: Yup.string().optional(),
        postCode: Yup.string().optional(),
      }),
    }),
  });
};

const buildPaymentsYup = () =>
  Yup.object().shape({
    gocardless: Yup.object()
      .shape({
        recurringPayment: Yup.boolean().required(),
      })
      .required(),
  });
