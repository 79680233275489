// Lib
import React from "react";
import { Form, FormikErrors, FormikProps, FormikTouched, withFormik } from "formik";
import { reach } from "yup";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { animateScroll as scroll } from 'react-scroll';

import API from "../lib/API";
import { getAppStageByComponentType } from "../lib/getAppStageByComponentType";

// Components
import PersonalDetails from "../components/switchForm/PersonalDetails";
import PaymentDetails from "../components/switchForm/PaymentDetails";
import Legals from "../components/switchForm/Legals";
import Header from "../components/header/Header";
import QuoteOverview from "../components/quotes/QuoteOverview";
import ProcessingSwitch from "../components/switchForm/ProcessingSwitch";
import Alert from "../components/misc/Alert";

// Store actions
import { updateJourney } from "../stores/actions/updateJourney";
import { updateStage } from "../stores/actions/updateStage";

//TS Definitions
import {AppStages, GoCardlessPayments, Journey, JourneyUtilityType, QuoteDetails} from "../models/journey.model";
import { DocusignSwitchResponseData, SwitchResponse } from "../models/switch-response.model";
import { AppLayoutData, JourneyPageLayout } from "../interfaces/Journey";
import { FormInput } from "../interfaces/Form";
import { ContactDetailsInterface, makeContactDetailsSchema } from "../schemas/ContactDetails";
import { SupplierConfig } from "../models/supplier-config.model";
import { SwitchFormState } from "../interfaces/SwitchPage";
import { ListOptions } from "../lib/formatAppData";
import { SaveQuoteModal } from "../components/SaveQuoteModal";
import { AppData } from "../providers/AppData";
import { environment } from "../lib/environment";
import { arrayContentsEqual } from "../lib/array-extensions";
import { NoPaymentDetails } from "../components/switchForm/NoPaymentDetails";
import { FuelType } from "../models/utility-detail";
import { GoCardlessPayment } from "../components/GoCardless/GoCardless";
import { analyticsPageView, analyticsEvent } from "../stores/analytics/actions";
import { tryGetLastCompletedStageName } from "../lib/tryGetLastCompletedStageName";
import { JourneyCompanyType } from "../interfaces/CompanyType";
import { FeatureToggle } from "../components/misc/FeatureToggle";
import {DirectDebit} from "../models/direct-debit.model";

type ComponentToComplete = {
    component: FormInput;
    ref: React.RefObject<HTMLDivElement> | undefined;
    checkIfValid?: () => Promise<boolean>
}
const getSuppliers = (journey: Journey) => new Set(journey?.utilities?.quoteDetails?.map(qd => qd.quote.supplier) ?? [])
const dispatchProps = { updateJourney, updateStage, analyticsPageView, analyticsEvent };

//TODO: Remove any
const mapStateToProps = (state: any) => state;
const connector = connect<any, typeof dispatchProps>(mapStateToProps, dispatchProps);

type SwitchProps = { listOptions: ListOptions }
type SwitchFormProps = ConnectedProps<typeof connector> & FormikProps<ContactDetailsInterface> & RouteComponentProps & JourneyPageLayout & SwitchProps;

class SwitchPage extends React.Component<SwitchFormProps, SwitchFormState>{
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>;

    private contactInformation: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private directDebit: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private legals: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private overview: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();
    private payments: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private processingSwitch: React.RefObject<ProcessingSwitch> | null = React.createRef<ProcessingSwitch>();
    schema: ReturnType<typeof makeContactDetailsSchema>;
    private componentsToComplete: Array<ComponentToComplete> = [];
    
    constructor(props: SwitchFormProps) {
        super(props);
        
        const suppliers = getSuppliers(props.journey as Journey);
        this.state = {
            processingSwitch: false,
            errors: [],
            supplierTerms: this.getSupplierTerms(),
            isSaveQuotesModalOpen: false,
            isSaveQuotesModalEmail: false,
            suppliers: suppliers,
            bankValidation: {
                loading: false,
                accountNumber: '',
                sortCode: '',
                isValidDetails: { isValid: null, error: ''}
            }
        }
        this.schema = makeContactDetailsSchema(
            this.props.listOptions, 
            suppliers, 
            this.props.components, 
            this.props.journey.companyType
        )
    }

    public componentDidMount = (): void => {
        this.props.analyticsPageView(window.location.pathname);

        if(document.scrollingElement !== null){
            document.scrollingElement.scrollTop = 0;
        }

        // Fix for formik & yup to know how many supplier legals there are
        if(this.state.supplierTerms.length >= 1){
            this.props.setFieldValue("legals.supplier_0", false);
        }
        if(this.state.supplierTerms.length >= 2){
            this.props.setFieldValue("legals.supplier_1", false);
        }

        this.componentsToComplete = this.props.components.map((component: FormInput): ComponentToComplete => {
            let ref;
            let checkIfValid;
            switch (component.type) {
                case "personal-details": 
                    ref = this.contactInformation; 
                    checkIfValid = this.checkContactInfoValidity; 
                    break;
                case "payment-details": 
                    ref = this.directDebit; 
                    checkIfValid = this.checkDirectDebitValidity; 
                    break;
                case "legals": 
                    ref = this.legals; 
                    checkIfValid = this.checkLegalsValidity; 
                    break;
                case "gocardless-payment": 
                    ref = this.payments; 
                    checkIfValid = this.checkPaymentsValidity; 
                    break;
            }
            return {
                component,
                ref,
                checkIfValid
            };
        });

        this.validateForm();
    }

    componentDidUpdate(prevProps: SwitchFormProps) {
        if (!arrayContentsEqual(this.props.completedStages, prevProps.completedStages)) {
            const [success, name] = tryGetLastCompletedStageName(this.props as {completedStages: AppStages[]});
            if (success) {
                this.props.analyticsEvent(name);
            }
        }
    }

    public onSubmit = async (): Promise<boolean> => {
        let goCardless: Partial<GoCardlessPayments> = {};
        return (await this.updateJourney({ goCardless }))
    }

    public updateJourney = async ({ goCardless }: { goCardless: Partial<GoCardlessPayments> }): Promise<boolean> => {
        if (this.props.values.contactInformation.dateOfBirth) {
            const dobArray = this.props.values.contactInformation.dateOfBirth?.split('/');
            this.props.values.contactInformation.dateOfBirth = dobArray[1] + '/' + dobArray[0] + '/' + dobArray[2];
        }
        
        const ddInfo = {
            ...this.props.values.directDebit,
            validationResponse: this.props.journey.directDebit.validationResponse
        } as DirectDebit;
        let journey : Journey = {
            ...this.props.journey,
            contactInformation : this.props.values.contactInformation,
            directDebit : ddInfo,
            legals: {
                mandatory: this.props.values.mandatory,
                optional: this.props.values.optional
            },
            payments: {
                ...this.props.values.payments, 
                gocardless: {
                    ...this.props.values.payments.gocardless, 
                    ...goCardless
                }
            },
            supplyCompany: {
                ...this.props.journey.supplyCompany,
                ...((this.context.toggles.show_microbusiness_checkbox && this.props?.values?.supplyCompany?.isMicroBusiness !== undefined) && {isMicroBusiness: this.props?.values?.supplyCompany?.isMicroBusiness})
            }
        }

        return await this.props.updateJourney(journey, false);
    }
    
    private scrollToRef = (ref: React.RefObject<HTMLDivElement> | React.RefObject<HTMLElement>) => {
        if(ref.current !== null){
            const offset = ref.current.getBoundingClientRect(),
                  top = (offset.top + (document.scrollingElement?.scrollTop ?? 0));

            setTimeout(() => {
                scroll.scrollTo(top);
            }, 100);
        }
    }

    private checkContactInfoValidity = async (): Promise<boolean> => {
        return await reach(this.schema, 'contactInformation', null, null)
            .isValid(this.props.values.contactInformation)
            .then((valid: boolean) => {
                this.props.updateStage(AppStages.ContactInformation, valid);
                return valid;
            });
    }

    private validateBankDetails = async (): Promise<boolean> => {
        const bankDetails = {
            accountNumber: this.state.bankValidation.accountNumber,
            sortCode: this.state.bankValidation.sortCode
        } as DirectDebit;
        
        this.setState({bankValidation: { ...this.state.bankValidation, loading: true } });
        
        return API.validateBankDetails(bankDetails, this.props.journey.utilities.quoteDetails[0].quote.supplier)
            .then(async resp => {
                if (resp.isValid) {
                    let journey: Journey = {
                        ...this.props.journey,
                        directDebit: { validationResponse: resp }
                    }

                    await this.props.updateJourney(journey, false);

                    this.setState({
                        errors: [],
                        bankValidation: { ...this.state.bankValidation, loading: false, 
                            isValidDetails: {isValid: true, error: ''} }
                    });
                    
                    return resp.isValid;
                }

                this.setState({
                    errors: ["Invalid bank details."],
                    bankValidation: {
                        ...this.state.bankValidation,
                        loading: false,
                        isValidDetails: {isValid: false, error: 'Please check the value is correct.'}
                    }
                });

                return resp.isValid;
            });
    };
    
    private checkDirectDebitValidity = async (): Promise<boolean> => {
        return await reach(this.schema, 'directDebit', null, null)
            .isValid(this.props.values.directDebit)
            .then(async (valid: boolean) => {
                if (valid && this.state.bankValidation.isValidDetails.isValid) {
                    this.props.updateStage(AppStages.DirectDebit, true);
                    return valid;
                }
                if (valid && this.state.bankValidation.accountNumber && this.state.bankValidation.sortCode
                && typeof this.state.bankValidation.accountNumber === 'string'
                    && typeof this.state.bankValidation.sortCode === 'string') {
                    const isValid = await this.validateBankDetails();
                    this.props.updateStage(AppStages.DirectDebit, isValid);
                    return isValid;                    
                }

                this.props.updateStage(AppStages.DirectDebit, valid);
                return valid;
            });
    }

    private checkPaymentsValidity = async (): Promise<boolean> => {
        return await reach(this.schema, 'payments', null, null)
            .isValid(this.props.values.payments)
            .then((valid: boolean) => {
                this.props.updateStage(AppStages.GoCardlessPayment, valid);
                return valid;
            });
    }

    private checkLegalsValidity = async (): Promise<boolean> => {
        return await Promise.allSettled([
            reach(this.schema, 'mandatory', null, null).isValid(this.props.values.mandatory),
            reach(this.schema, 'optional', null, null).isValid(this.props.values.optional),
            reach(this.schema, 'legals', null, null).isValid(this.props.values.legals)
        ]).then(([mandatory, optional, legals]) => {
            const mandatoryValue = mandatory.status === 'fulfilled' ? mandatory.value : false
            const optionalValue = optional.status === 'fulfilled' ? optional.value : false
            const legalsValue = legals.status === 'fulfilled' ? legals.value : false
            const valid = mandatoryValue && optionalValue && legalsValue

            this.props.updateStage(AppStages.Legal, valid);
            return valid;
        })
    }

    private updateSwitchStatus = (envelopeId: string | undefined) => {
        if (envelopeId) {
            let journey : Journey = {
                ...this.props.journey,
                metadata: {
                    ...this.props.journey.metadata,
                    switchStatus: "Docusign Pending"
                }
            }
            this.props.updateJourney(journey, false);
        }
    }

    public completeJourney = () => {
        API.checkJourneyIsComplete(this.props.journey.uid)
            .then((resp: boolean) => {

                if(!resp){
                    API.completeJourney(this.props.journey.uid)
                        .then((resp: boolean) => {
                            console.log('completedJourney',resp)
                        });
                }
            });
    }
    
    private handleSwitchResponse = (switchResponse: Array<SwitchResponse>): void => {        
        const requiresDocusign = switchResponse.find((quote: SwitchResponse) => quote.status === "pending-docusign");
        const emailDocusign = switchResponse.find((quote: SwitchResponse) => quote.status === "email-docusign");

        if (requiresDocusign && (requiresDocusign?.data as DocusignSwitchResponseData)?.url) {
            this.updateSwitchStatus((requiresDocusign.data as DocusignSwitchResponseData)?.envelopeId ?? undefined);
            window.location.assign((requiresDocusign.data as DocusignSwitchResponseData).url);            
        } else if (emailDocusign) {
            this.updateSwitchStatus((emailDocusign.data as DocusignSwitchResponseData)?.envelopeId ?? undefined);
            this.props.history.push(`/journey/completed?journey=${this.props.journey.uid}&isEmailDocusign=true`);            
        } else {
            this.props.history.push(`/journey/completed?journey=${this.props.journey.uid}`);
        }
    }

    private requiresDocusign = (): boolean => {
        const suppliers: SupplierConfig[] = (Object.values(this.props.suppliers));

        if(!suppliers.length){
            return false;
        }

        const requiresDocusign = this.props.journey.utilities.quoteDetails.filter((quoteDetails: QuoteDetails) => {
            let supplier = suppliers.find((supplier: SupplierConfig) => supplier.reference === quoteDetails.quote.supplier);
            return supplier?.captureDocumentSignature;
        });

        return requiresDocusign.length;
    }

    public startSwitch = (): void => {
        this.completeJourney();
        this.setState({ processingSwitch : true, errors : [] }, () => {
            API.switch(this.props.journey.uid)
                .then(this.handleSwitchResponse)
                .catch(err => {
                    console.warn(err);
                    this.setState({
                        errors : ["An Error occurred, please try again."],
                        processingSwitch: false
                    });
                });
        });
    }

    private validateForm = () => this.props.validateForm().then(async (errors : FormikErrors<ContactDetailsInterface>) => {
        for (let index = 0; index < this.componentsToComplete.length; index++) {
            const element = this.componentsToComplete[index];
            const isValid = element.checkIfValid && await element.checkIfValid()
            const onLastComponent = index === this.componentsToComplete.length - 1;

            if (isValid) {
                if (onLastComponent) {
                    this.scrollToRef(this.overview);
                } else {
                    const nextComponent = this.componentsToComplete[index + 1];
                    if (nextComponent?.ref) {
                        this.scrollToRef(nextComponent.ref);
                    }
                }
            } else {
                break;
            }
        }
    });

    public handleFormChange = (name: string, value: string|boolean|null): void => {
        this.props.setFieldValue(name, value);
        
        if (name === 'directDebit.accountNumber') {
            this.setState({ bankValidation: { ...this.state.bankValidation, isValidDetails: { isValid: null }, accountNumber: value } })
        }
        if (name === 'directDebit.sortCode') {
            this.setState({ bankValidation: { ...this.state.bankValidation, isValidDetails: { isValid: null }, sortCode: value } })
        }
        
        this.validateForm();
    }

    private renderFormFieldset = (component: FormInput, idx: number): JSX.Element => {
        const errors = this.props.errors as FormikErrors<ContactDetailsInterface>;
        const touched = this.props.touched as FormikTouched<ContactDetailsInterface>;
        const appStage = getAppStageByComponentType(component.type);
        const className = (this.props.completedStages.indexOf(appStage) !== -1) ? "form__fieldset completed" : "form__fieldset";

        switch (component.type){
            case "personal-details":
                const isSoleTraderJourney = this.props.journey.companyType === JourneyCompanyType.SoleTrader;
                return (
                    <div key={`fieldset-${idx}`} id={"personal-details"} className={className} ref={this.contactInformation}>
                        <PersonalDetails
                            isSoleTraderJourney={isSoleTraderJourney} 
                            errors={errors}
                            touched={touched}
                            values={this.props.values}
                            onFieldChange={this.handleFormChange} />
                    </div>
                );
            case "payment-details":
                const isDualFuelAndShouldShowNoPaymentText = 
                    (this.props.journey as Journey).journeyUtilityType === 
                        JourneyUtilityType.DualFuel && 
                        this.state.suppliers.size > 1 && 
                        this.state.suppliers.has('valda');
          
                return (
                    <div key={`fieldset-${idx}`} id={"payment-details"} className={className} ref={this.directDebit}>
                        {this.state.suppliers.size === 1 && this.state.suppliers.has('test') ? (
                            <NoPaymentDetails />
                        ) : (
                            <PaymentDetails
                                showNoPaymentText={isDualFuelAndShouldShowNoPaymentText} 
                                errors={errors}
                                touched={touched}
                                values={this.props.values}
                                onFieldChange={this.handleFormChange} 
                                validDetails={this.state.bankValidation.isValidDetails}
                                isValidating={this.state.bankValidation.loading } />
                        )}
                    </div>
                );
            case "legals":
                return (
                    <div key={`fieldset-${idx}`} id={"legals"} className={className} ref={this.legals}>
                        <Legals 
                            errors={errors}
                            touched={touched}
                            listOptions={this.props.listOptions} 
                            onFieldChange={this.handleFormChange}
                            supplierTerms={this.state.supplierTerms}
                        />
                    </div>
                );
            case "gocardless-payment":
                return (
                    <div key={`fieldset-${idx}`} id={"gocardless-payment"} className={className} ref={this.payments}>
                        <GoCardlessPayment
                            errors={errors}
                            touched={touched}
                            onFieldChange={this.handleFormChange}
                            values={this.props.values}
                        />
                    </div>
                );
            default:
                return (<></>);
        }
    }

    public onKeyDown = (event: React.KeyboardEvent): void => {
        if ((event.charCode || event.keyCode) === 13 || event.code === "Enter") {
            event.preventDefault();
        }
    }

    //TODO: Add types
    private getSupplierTerms = () => {
        let suppliers = this.props.journey.utilities.quoteDetails.map((quoteDetail: QuoteDetails, idx: number) => {
            let suppliers = Object.values(this.props.suppliers) as Array<SupplierConfig>;
            const supplier = suppliers.find((supplier: SupplierConfig) => supplier.reference === quoteDetail.quote.supplier);
            const url = supplier?.terms?.find(
                term => term?.type 
                    && (FuelType[term.type.fuelType] === (quoteDetail.quote.fuelType as unknown) || term.type.fuelType === quoteDetail.quote.fuelType) 
                    && (term.type.term === 0 || term.type.term === quoteDetail.quote.termInMonths)
            )?.url ?? supplier?.tAndCsUrl;

            return {
                showSupplierTAndCs: supplier?.showSupplierTAndCs ?? false,
                name: supplier?.name ?? '',
                tAndCsUrl: url
            };
        });

        suppliers = new Set(suppliers);
        suppliers = [...suppliers];

        suppliers = suppliers.filter((supplier: SupplierConfig) => supplier.showSupplierTAndCs)

        return suppliers;
    }

    private handleModalClick = () => {
        let emailRegex = /\S+@\S+\.\S+/;
        let isEmailValid = emailRegex.test(this.props.journey.contactInformation.email)

        if(isEmailValid) {
            this.setState({isSaveQuotesModalOpen: true, isSaveQuotesModalEmail: true})
        } else {
            this.setState({isSaveQuotesModalOpen: true, isSaveQuotesModalEmail: false})
        }
    }

    public render = (): JSX.Element => {
        const requiresDocusign = this.requiresDocusign();

        return (
            <>
                <Header />
                {environment.REACT_APP_IS_SALES_AGENT_PORTAL && (
                    <section id={"filters"}>
                        <div className={"container-full"}>
                            <FeatureToggle
                                name={"show_save_quotes_btn_switch_page"}
                                fallback={false}
                                render={
                                    <div className={"save-switch"}>
                                        <div className={"save-quotes__actions"}>
                                            <button id={"save-quotes"}
                                                type={"button"}
                                                className={"button button--outline"}
                                                onClick={() => this.handleModalClick()}
                                                disabled={this.state.isSaveQuotesModalEmail}>
                                                    {!this.state.isSaveQuotesModalEmail 
                                                        ? this.context.labels.quotes_save_quotes_button 
                                                        : this.context.labels.quotes_save_quotes_button_sent}
                                            </button>
                                         </div>
                                    </div>
                                }
                                otherwiseRender={<></>}
                                />
                        </div>
                    </section>
                )}
                <Form id={"app-body"}
                      className={"switch-details"}
                      onKeyDown={this.onKeyDown}
                      onSubmit={(event: React.FormEvent) => {event.preventDefault()}}>
                    <div className={"container-full"}>
                        {this.state.errors.map((errorString: string)=>{
                            return <Alert type={"danger"} message={errorString} />
                        })}
                        <div className={"grid"}>
                            <section className={"col-md-8"}>
                                <div className={"form form--details"}>
                                    {this.props.components.map(this.renderFormFieldset)}
                                </div>
                            </section>
                            <aside className={"col-md-4"} ref={this.overview}>
                                <QuoteOverview showButton={ this.props.isValid }
                                               suppliers={this.props.suppliers}
                                               forceDetailsOpen={true}
                                               onSubmit={this.onSubmit}
                                               onCompleted={this.startSwitch} />
                            </aside>
                        </div>
                    </div>
                </Form>
                <ProcessingSwitch 
                    loading={this.state.processingSwitch}
                    requiresDocusign={requiresDocusign}
                    ref={this.processingSwitch}
                />
                {this.state.isSaveQuotesModalOpen && <SaveQuoteModal 
                    journey={this.props.journey}
                    typeOfSave={"save-quotes"}
                    isSaveQuotesModalOpen={this.state.isSaveQuotesModalOpen}
                    isSaveQuotesModalEmail={this.state.isSaveQuotesModalEmail}
                    onClose={() => this.setState({isSaveQuotesModalOpen: false})}
                />}
            </>
        );
    }
}

export default withRouter(connector(withFormik<ConnectedProps<typeof connector>, ContactDetailsInterface>(
    {
        validationSchema: (props: SwitchFormProps) => {
            const journey: Journey = props.journey;
            const suppliers = getSuppliers(journey);
            return makeContactDetailsSchema(
                props.listOptions, 
                suppliers, 
                props.components, 
                journey.companyType
            );
        },
        mapPropsToErrors: (props : FormikErrors<SwitchFormProps>) => {
            return props;
        },
        mapPropsToTouched: (props:FormikTouched<SwitchFormProps>) => {
            return props;
        },
        mapPropsToValues: (props: ConnectedProps<typeof connector> & FormikProps<SwitchFormProps>) => {
            const mandatory: {[key: string]: boolean} = {}
            for (const option of props.listOptions.mandatory_legal_options) {
                mandatory[option.key] = false;
            }
            const optional: {[key: string]: boolean} = {}
            for (const option of props.listOptions.optional_legal_options) {
                optional[option.key] = false;
            }
            return {
                contactInformation : props.journey.contactInformation as object,
                directDebit : {
                    ...{
                        ...props.journey.directDebit,
                        authorisation : true
                    },
                    sameBillingAddress : (typeof props.journey.directDebit.sameBillingAddress === "undefined" || props.journey.directDebit.sameBillingAddress)
                },
                mandatory,
                optional,
                payments: props.journey.payments
            } as ContactDetailsInterface;
        },
        handleSubmit: () => {}
    }
)(SwitchPage)));